var Homepage = {
    start: function(){
        $('#header,.subpage-homepage #visual .owl-carousel .owl-nav,.subpage-homepage #visual .owl-carousel .owl-dots').animate({opacity:1}, 2000);
        
        var currentPosition = $("#start-logo-layer img").offset();
        
        $("#start-logo-layer img")
            .css({
                top         : currentPosition.top,
                left        : currentPosition.left,
                position    : 'absolute',
                'margin-top': 0
            })
            .animate(
                { 
                    height:     $('#logo img').height(),
                    top:        $('#logo img').offset().top,
                    left:       $('#logo img').offset().left
                },
                1000,
                function(){
                    $("#start-logo-layer").remove();
                    $('#logo img').css({opacity:1});
                }
            )
        ;
    }
};
